import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Supplier.scss';
import { Link } from 'react-router-dom';
import ContactInformation from '../ContactInformation/ContactInformation';
import SupplierServices from '../SupplierGoodsServices/SupplierGoodsServices';
import SupplierCertifications from '../SupplierCertifications/SupplierCertifications';
import SupplierUtility from './services/utility.service';
import SupplierConfig from './services/config.service';
import SupplierHttp from './services/http.service';
import { Card, CardContent } from '@material-ui/core';

export default class Supplier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactInfo: null,
      errorMessage: null,
      services: null,
      supplier: null,
    };

    this.getSupplierById(props.match.params.id);
  }

  getSupplierById(id) {
    SupplierHttp.getSupplierById(id)
      .then(supplier => {
        this.setState(() => ({
          contactInfo: SupplierUtility.getContactInfo(supplier),
          errorMessage: null,
          supplier,
          services: SupplierUtility.getServices(
            SupplierConfig.codeTypePropertyNames,
            supplier
          ),
        }));
      })
      .catch(errorMessage => {
        this.setState(() => ({
          contactInfo: null,
          errorMessage,
          supplier: null,
          services: null,
        }));
      });
  }

  render() {
    const { contactInfo, errorMessage, services, supplier } = this.state;

    if (errorMessage) {
      return (
        <article>
          <h1>An Error Occurred.</h1>

          <p>{errorMessage}</p>
        </article>
      );
    }

    if (supplier === null) {
      return <span>Loading&hellip;</span>;
    }

    return (
      <div>
        <article className="supplier-container">
          <Card className="supplier">
            <CardContent>
              <h3>
                {supplier.companyName}
              </h3>

              <div>
                <SupplierCertifications certifications={supplier.certifications} />

                <SupplierServices
                  services={services}
                  supplierId={supplier.objectID}
                  truncateCount={0}
                  useEllipsis={false}
                />

                <ContactInformation contactInfo={contactInfo} />
              </div>
            </CardContent>
          </Card>
        </article>

        <p>
          <Link
            to={{
              pathname: `/`,
            }}
          >
            Home
          </Link>
        </p>
      </div>
    );
  }
}

Supplier.propTypes = {
  match: PropTypes.object.isRequired,
};
