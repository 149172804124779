import React from 'react';
import {
  Highlight,
  Hits,
  HitsPerPage,
  InstantSearch,
  Pagination,
  ScrollTo,
  SearchBox,
} from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';

import Facets from '../Facets/Facets';
import AlgoliaConfig from '../Shared/algolia-config.service';
import withURLSync from './URLSync';
import SupplierHttpService from '../Supplier/services/http.service';
import './Search.scss';
import SupplierUtility from '../Supplier/services/utility.service';
import SupplierConfig from '../Supplier/services/config.service';
import logo from './img/logo.jpg';
import SupplierCertifications from '../SupplierCertifications/SupplierCertifications';
import SupplierGoodsServices from '../SupplierGoodsServices/SupplierGoodsServices';
import ContactInformation from '../ContactInformation/ContactInformation';

const App = props => (
  <InstantSearch
    searchClient={SupplierHttpService.searchClient}
    indexName={AlgoliaConfig.getIndexName()}
    searchState={props.searchState}
    createURL={props.createURL}
    onSearchStateChange={props.onSearchStateChange}
  >
    <div className="grid-container">
      <div>
        <div className="logo-container">
          <img src={logo} alt="Logo" width="144" height="47" />
        </div>
      </div>

      <ScrollTo>
        <SearchBox autoFocus={true} showLoadingIndicator={true} />
      </ScrollTo>
    </div>

    <div className="grid-container">
      <div>
        <HitsPerPage
          defaultRefinement={12}
          items={[
            { value: 12, label: 'Show 12 results' },
            { value: 36, label: 'Show 36 results' },
          ]}
        />

        <Facets />
      </div>

      <div className="search-panel">
        <div className="search-panel__results">
          <div className="results-wrapper">
            <div>
              <Hits hitComponent={Hit} />
              <footer>
                <Pagination showLast={true} />
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </InstantSearch>
);

const Hit = ({ hit }) => {
  let contactInfo = SupplierUtility.getContactInfo(hit);
  contactInfo = {
    ...contactInfo,
    _highlightResult: hit._highlightResult,
  };

  const goodsAndServices = SupplierUtility.getServices(
    SupplierConfig.codeTypePropertyNames,
    hit
  );

  return (
    <article className="hit">
      <div>
        <Link
          to={{
            pathname: `/supplier/${hit.objectID}`,
          }}
        >
          <h3>
            <Highlight hit={hit} attribute="companyName" />
          </h3>
        </Link>

        <SupplierCertifications certifications={hit.certifications} />

        <SupplierGoodsServices
          services={goodsAndServices}
          supplierId={hit.objectID}
          truncateCount={4}
          useEllipsis={true}
        />

        <ContactInformation contactInfo={contactInfo} />
      </div>
    </article>
  );
};

export default withURLSync(App);
