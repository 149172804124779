import React, { Component } from 'react';
import './Header.scss';

export default class Header extends Component {
  render() {
    return (
      <header className="container header">
        <h1 className="header-title">
          <a href="/">VT Diverse Supplier Search Tool</a>
        </h1>
      </header>
    );
  }
}
