// Public interface.
const algoliaService = {
  // Properties.
  applicationId: 'WFSG9GGWNG',
  environmentProductionSentinel: 'production',

  // Methods.
  getApiKey,
  getIndexName,
};

// Private properties.
const apiDevelopment = {
  index: 'swam_dev',
  key: 'f592586f94233b5613bc47d546bc8734',
};
const apiProduction = {
  index: 'swam_prod',
  key: '2969cea40627c9dbf022a7690efb8524',
};

// Private methods.

/**
 * Dynamically return the API key based on whether the
 * current Node process was started in production mode.
 *
 * @returns {string} API key.
 */
function getApiKey() {
  if (
    process.env.REACT_APP_ALGOLIA_ENVIRONMENT !==
    algoliaService.environmentProductionSentinel
  ) {
    return apiDevelopment.key;
  }

  return apiProduction.key;
}

/**
 * Dynamically return the Algolia index based on whether
 * the current Node process was started in production mode.
 *
 * @returns {string} Index name.
 */
function getIndexName() {
  if (
    process.env.REACT_APP_ALGOLIA_ENVIRONMENT !==
    algoliaService.environmentProductionSentinel
  ) {
    return apiDevelopment.index;
  }

  return apiProduction.index;
}

export default algoliaService;
