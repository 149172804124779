const service = {
  getContactInfo,
  getServices,
};

export default service;

function getContactInfo(supplier) {
  return {
    name: supplier.contactName,
    street: supplier.physicalAddress,
    city: supplier.city,
    state: supplier.state,
    zip: supplier.zip,
    phone: supplier.contactPhone,
    email: supplier.contactEmail,
  };
}

function getServices(codeTypes, supplier) {
  const services = [];

  for (const codeType of codeTypes) {
    if (supplier[codeType]) {
      const codes = supplier[codeType].map(code => code);
      services.push(...codes);
    }
  }

  return services;
}
