const service = {
  certificationsPropertyName: 'certifications',
  certificationsSortOrder: new Map([
    ['small', [1, 'Small']],
    ['womanOwned', [2, 'Woman Owned']],
    ['minorityOwned', [3, 'Minority Owned']],
    ['micro', [4, 'Micro']],
    ['acdbe', [5, 'ACDBE']],
    ['serviceDisabledVeteran', [6, 'Service Disabled Veteran']],
    ['dbe', [7, 'DBE']],
    ['eso', [8, 'ESO']],
  ]),
  certificationTypeKeyPropertyName: 'certificationTypeKey',
};

export default service;
