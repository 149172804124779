import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SupplierCertifications.scss';
import CertificationService from './services/utility.service';
import CertificationsConfigService from '../SupplierCertifications/services/config.service';

export default class SupplierCertifications extends Component {
  constructor(props) {
    super(props);

    this.certificationsHtml = this.getCertificationsHtml(
      props.certifications.slice()
    );
  }

  getCertificationsHtml(certifications) {
    const sortedCertifications = CertificationService.sortCertifications(
      certifications
    );

    const certificationsHtml = [];

    for (let i = 0, length = sortedCertifications.length; i < length; i++) {
      certificationsHtml.push(
        <li key={sortedCertifications[i]}>
          {
            CertificationsConfigService.certificationsSortOrder.get(
              sortedCertifications[i]
            )[1]
          }
        </li>
      );
    }

    return certificationsHtml;
  }

  render() {
    return (
      <div className="supplier-certifications">
        <h5>Certifications</h5>
        <ul>{this.certificationsHtml}</ul>
      </div>
    );
  }
}

SupplierCertifications.propTypes = {
  certifications: PropTypes.array.isRequired,
};
