import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import './SupplierGoodsServices.scss';
import SortService from '../Shared/sort.service';

const SupplierGoodsServices = ({ supplierId, services, truncateCount, useEllipsis }) => {
  const isTruncated = truncateCount !== undefined;

  const servicesHtml = getServicesHtml(
    services,
    isTruncated,
    truncateCount,
    useEllipsis,
    supplierId
  );
  const goodsServicesListClassNames = initializeGoodsServicesListClasses(
    useEllipsis
  );

  return (
    <div>
      <h5>Goods and Services</h5>
      <ul className={goodsServicesListClassNames}>
        {servicesHtml}
      </ul>
    </div>
  );
}

function getServicesHtml(
  services,
  isTruncated = false,
  truncateCount = 4,
  useEllipsis,
  supplierId
) {
  // Remove duplicate services.
  const distinctServices = new Set(
    services.map(service => service.description)
  );

  // Sort services.
  const sortedServices = Array.from(distinctServices).sort(
    SortService.naturalSort
  );

  const servicesHtml = [];
  let count = 0;

  for (const service of sortedServices) {
    let listItemHtml = <li key={service}>{service}</li>;

    if (useEllipsis) {
      listItemHtml = (
        <li key={service}>
          <Tooltip title={service} placement="bottom-start">
            <span>{service}</span>
          </Tooltip>
        </li>
      );
    }

    servicesHtml.push(listItemHtml);
    count++;

    if (isTruncated && count === truncateCount) {
      servicesHtml.push(
        <li key="more" className="more">
          <Link
            to={{
              pathname: `/supplier/${supplierId}`,
            }}
          >
            Show more&hellip;
          </Link>
        </li>
      );
      break;
    }
  }

  return servicesHtml;
}

function initializeGoodsServicesListClasses(useEllipsis) {
  const listClasses = ['supplier-goods-services'];

  if (useEllipsis) {
    listClasses.push('use-ellipsis');
  }

  return listClasses.join(' ');
}

export default SupplierGoodsServices;