import React, { Component } from 'react';
import {
  ClearRefinements,
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';
import './Facets.scss';
import ConfigService from './services/config.service';
import CertificationsConfigService from '../SupplierCertifications/services/config.service';
import CertificationsService from '../SupplierCertifications/services/utility.service';

export default class Facets extends Component {
  transformVtVendorLabel(vtVendorArr) {
    for (const val of vtVendorArr) {
      if (val.label === 'true') {
        val.label = 'Yes';
      } else if (val.label === 'false') {
        val.label = 'No';
      }
    }

    return vtVendorArr;
  }

  sortCertificationsAndSetLabels(certificationsArr) {
    for (const certificationType of certificationsArr) {
      CertificationsService.addCertificationTypeKeyProperty(certificationType);
      certificationType.label = CertificationsConfigService.certificationsSortOrder.get(
        certificationType[
          CertificationsConfigService.certificationTypeKeyPropertyName
        ]
      )[1];
    }

    return CertificationsService.sortCertifications(certificationsArr);
  }

  render() {
    return (
      <aside>
        <ClearRefinements
          translations={{
            reset: 'Clear all filters',
          }}
        />

        <Panel header={<h5>Current VT Vendor</h5>}>
          <RefinementList
            attribute={ConfigService.bannerVendorIdPropertyName}
            transformItems={this.transformVtVendorLabel}
          />
        </Panel>

        <Panel header={<h5>Certifications</h5>}>
          <RefinementList
            attribute="certifications"
            transformItems={this.sortCertificationsAndSetLabels}
          />
        </Panel>

        <Panel header={<h5>Business Category</h5>}>
          <RefinementList attribute="businessCategory" limit={5} showMore />
        </Panel>

        <Panel header={<h5>Region</h5>}>
          <RefinementList attribute="region" limit={5} />
        </Panel>

        <Panel header={<h5>State</h5>}>
          <RefinementList attribute="state" limit={5} searchable showMore />
        </Panel>

        <Panel header={<h5>Zip Code</h5>}>
          <RefinementList attribute="zip" limit={5} searchable showMore />
        </Panel>
      </aside>
    );
  }
}
