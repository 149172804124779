const isCaseInsensitive = true;

const re = /(^([+-]?(?:0|[1-9]\d*)(?:\.\d*)?(?:[eE][+-]?\d+)?)?$|^0x[0-9a-f]+$|\d+)/gi;
const sre = /(^[ ]*|[ ]*$)/g;
const dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[/-]\d{1,4}[/-]\d{1,4}|^\w+, \w+ \d+, \d{4})/;
const hre = /^0x[0-9a-f]+$/i;
const ore = /^0/;

/* Performs natural sort to an array.
 * Adapted from https://github.com/Bill4Time/javascript-natural-sort
 */
// eslint-disable-next-line complexity
const naturalSort = (a, b) => {
  const i = s => (isCaseInsensitive && `${s}`.toLowerCase()) || `${s}`;

  // convert all to strings strip whitespace
  const x = i(a).replace(sre, '') || '';
  const y = i(b).replace(sre, '') || '';
  // chunk/tokenize
  const xN = x
    .replace(re, '\0$1\0')
    .replace(/\0$/, '')
    .replace(/^\0/, '')
    .split('\0');
  const yN = y
    .replace(re, '\0$1\0')
    .replace(/\0$/, '')
    .replace(/^\0/, '')
    .split('\0');

  // numeric, hex or date detection
  const xMatch = x.match(hre);

  const xD = xMatch
    ? parseInt(xMatch[0], 16)
    : xN.length !== 1 && x.match(dre) && Date.parse(x);
  const yMatch = y.match(hre);
  const yD = yMatch
    ? parseInt(yMatch[0], 16)
    : xD && y.match(dre) && Date.parse(y);

  let oFxNcL;
  let oFyNcL;
  // first try and sort Hex codes or Dates
  if (yD) {
    if (xD < yD) {
      return -1;
    } else if (xD > yD) {
      return 1;
    }
  }
  // natural sorting through split numeric strings and default strings
  for (
    let cLoc = 0, numS = Math.max(xN.length, yN.length);
    cLoc < numS;
    cLoc++
  ) {
    // find floats not starting with '0', string or 0 if not defined (Clint Priest)
    oFxNcL =
      (!(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc])) || xN[cLoc] || 0;
    oFyNcL =
      (!(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc])) || yN[cLoc] || 0;
    // handle numeric vs string comparison - number < string - (Kyle Adams)
    if (isNaN(oFxNcL) !== isNaN(oFyNcL)) {
      return isNaN(oFxNcL) ? 1 : -1;
      // rely on string comparison if different types - i.e. '02' < 2 != '02' < '2'
    } else if (typeof oFxNcL !== typeof oFyNcL) {
      oFxNcL += '';
      oFyNcL += '';
    }
    if (oFxNcL < oFyNcL) {
      return -1;
    }
    if (oFxNcL > oFyNcL) {
      return 1;
    }
  }
  return 0;
};

const naturalSortArrayByProperty = (arr, property) => {
  // Create a position-based map of the property values.
  const mappedPropertyValues = arr.map((element, idx) => ({
    index: idx,
    propertyValue: element[property],
  }));

  // Call natural sorting method to sort the list of property values.
  mappedPropertyValues.sort((a, b) =>
    naturalSort(a.propertyValue, b.propertyValue)
  );

  // Use the index of the sorted list to return a sorted list of the original array.
  return mappedPropertyValues.map(element => arr[element.index]);
};

const compareItemsByUnicodeValue = (firstItem, secondItem) => {
  if (firstItem > secondItem) {
    return 1;
  }

  if (firstItem < secondItem) {
    return -1;
  }

  return 0;
};

/**
 * Sort an array of items according to the quicksort algorithm. This
 * implementation DOES NOT sort in place. Use the returned array.
 *
 * @param {Array} array Items to sort
 * @param {Function} compareItems Custom function for comparing items.
 *
 * @return {Array} Sorted items.
 */
const quickSort = (array, compareItems = compareItemsByUnicodeValue) => {
  // Arrays with less than two elements are already sorted.
  if (array.length < 2) {
    return array;
  }

  const pivotValue = array[0];
  const valuesLessThanPivot = [];
  const valuesGreaterOrEqualToPivot = [];

  for (let i = 1, length = array.length; i < length; i++) {
    const comparisonSentinel = compareItems(array[i], pivotValue);
    if (comparisonSentinel === -1) {
      valuesLessThanPivot.push(array[i]);
    } else {
      valuesGreaterOrEqualToPivot.push(array[i]);
    }
  }

  return quickSort(valuesLessThanPivot, compareItems).concat(
    pivotValue,
    quickSort(valuesGreaterOrEqualToPivot, compareItems)
  );
};

export default {
  naturalSort,
  naturalSortArrayByProperty,
  quickSort,
};
