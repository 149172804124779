import React from 'react';
import { Highlight } from 'react-instantsearch-dom';

import './ContactInformation.scss';

const ContactInformation = ({ contactInfo }) => {
  return (
    <div className="contact-information">
      <h5>Contact Information</h5>

      <address>
        {contactInfo.name}
        <br />
        {contactInfo.street}
        <br />
        <CityStateZip contactInfo={contactInfo} />
        <br />
        <a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a>
        <br />
        <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
        <br />
      </address>
    </div>
  );
}

const CityStateZip = ({ contactInfo }) => {
  if (contactInfo._highlightResult) {
    return (
      <span>
        <Highlight hit={contactInfo} attribute="city" />,&nbsp;{contactInfo.state}&nbsp;
        <Highlight hit={contactInfo} attribute="zip" />
      </span>
    )
  }

  return (
    <span>
      {contactInfo.city},&nbsp;{contactInfo.state}&nbsp;{contactInfo.zip}
    </span>
  )
}

export default ContactInformation;
