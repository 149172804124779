import ConfigService from './config.service';
import SortService from '../../Shared/sort.service';

const addCertificationTypeKeyProperty = certificationType => {
  if (
    !certificationType.hasOwnProperty(
      ConfigService.certificationTypeKeyPropertyName
    )
  ) {
    // eslint-disable-next-line no-param-reassign
    certificationType[ConfigService.certificationTypeKeyPropertyName] =
      certificationType.label;
  }
};

const getCertificationSortOrder = certificationKey => {
  if (!ConfigService.certificationsSortOrder.has(certificationKey)) {
    throw new Error(`Certification is unknown: ${certificationKey}.`);
  }
  return ConfigService.certificationsSortOrder.get(certificationKey)[0];
};

const compareCertificationSortOrder = (
  firstCertification,
  secondCertification
) => {
  let firstCertificationValue = firstCertification;
  let secondCertificationValue = secondCertification;

  if (
    firstCertification.hasOwnProperty(
      ConfigService.certificationTypeKeyPropertyName
    )
  ) {
    firstCertificationValue =
      firstCertification[ConfigService.certificationTypeKeyPropertyName];
  }

  if (
    secondCertification.hasOwnProperty(
      ConfigService.certificationTypeKeyPropertyName
    )
  ) {
    secondCertificationValue =
      secondCertification[ConfigService.certificationTypeKeyPropertyName];
  }

  const firstCertificationSortValue = getCertificationSortOrder(
    firstCertificationValue
  );
  const secondCertificationSortValue = getCertificationSortOrder(
    secondCertificationValue
  );

  if (firstCertificationSortValue > secondCertificationSortValue) {
    return 1;
  }

  if (firstCertificationSortValue < secondCertificationSortValue) {
    return -1;
  }

  return 0;
};

const sortCertifications = certifications => {
  if (!Array.isArray(certifications)) {
    throw new TypeError(`Certifications parameter must be of type Array.`);
  }

  return SortService.quickSort(certifications, compareCertificationSortOrder);
};

const service = {
  addCertificationTypeKeyProperty,
  sortCertifications,
};

export default service;
